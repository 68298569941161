import PropTypes from 'prop-types'
import Image from 'next/image';
import { urlFor } from '@/lib/sanity'
import { Linker } from '@/components/content/linker'
import { format, parseISO } from 'date-fns'
import { Grid } from "@/components/content/grid";

const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="responsive"
    width={adjustedWidth}
    height={adjustedHeight}
    // className="object-cover object-center w-full"
    className='object-cover object-center w-full transition-all duration-500 lg:h-48 md:h-36 hover:scale-110'
  />
  )
}

function Figure(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 615;
  const adjustedHeight = 300;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

function FigureLarge(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 918;
  const adjustedHeight = 448;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

BlogView.propTypes = {
  data: PropTypes.array
}

export function BlogView(props: { data: any; }) {
  const { data } = props
  return (
    <div>
    {/* Latest Blog Post */}

    {data?.map(post =>
      <article key={post._id} className="flex flex-col mb-12 overflow-hidden rounded-md shadow-md">
        {post?.mainImage ?
          <div className="relative flex-shrink-0">
            <Linker url={`/blog/${post.slug}`}>
              <FigureLarge {...post.mainImage} />
            </Linker>
            {/* <div className='absolute bottom-4 left-4'>
              <h2 className='text-2xl font-bold md:text-3xl lg:text-4xl bg-black py-2 px-2.5 text-white rounded-md'>Latest Post</h2>
            </div> */}
            <div className="absolute px-2 py-1 text-sm text-white bg-gray-900 rounded shadow top-4 right-4">
              <time dateTime={post.publishedAt.utc}>{format(parseISO(post.publishedAt.utc), 'MMM do, yyyy')}</time>
            </div>
          </div> :
          <div className="relative flex-shrink-0 overflow-hidden">
            <Linker url={`/blog/${post.slug}`}>
              <figure className='bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative'>
              </figure>
            </Linker>
            {/* <div className='absolute bottom-4 left-4'>
              <h2 className='text-2xl font-bold md:text-3xl lg:text-4xl bg-black py-2 px-2.5 text-white rounded-md'>Latest Post</h2>
            </div> */}

            <div className="absolute px-2 py-1 text-sm text-white bg-gray-900 rounded shadow top-4 right-4">
              <time dateTime={post.publishedAt.utc}>{format(parseISO(post.publishedAt.utc), 'MMM do, yyyy')}</time>
            </div>
          </div>
        }

        <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
          <div className="flex-1">

          {post?.category?.slug &&
            <p className="text-sm font-medium tracking-widest text-gray-500 uppercase title-font">
              <Linker url={`/blog/category/${post.category.slug}`} classes="hover:underline">
                {post.category.title}
              </Linker>
            </p>}

            <h3 className="mt-2 mb-1 text-2xl font-semibold text-gray-900">
              <a href={`/blog/${post.slug}`} className="hover:underline">  {post.title}
              </a>
            </h3>

            {post.summary && <p className="text-lg text-gray-500">{post.summary}</p>}
          </div>

        </div>
      </article>
    ).shift()}

    {/* Post View minus the latest blog post */}
    <Grid gridSize={2}>
    {data?.slice(1).map(post =>
      <article key={post._id} className="flex flex-col overflow-hidden rounded-md shadow-md">

        {post?.mainImage ?
          <div className="relative flex-shrink-0">
            <Linker url={`/blog/${post.slug}`}>
              <Figure {...post.mainImage} />
            </Linker>
            <div className="absolute px-2 py-1 text-sm text-white bg-gray-900 rounded shadow top-4 right-4">
              <time dateTime={post.publishedAt.utc}>{format(parseISO(post.publishedAt.utc), 'MMM do, yyyy')}</time>
            </div>
          </div> :
          <div className="relative flex-shrink-0 overflow-hidden">
            <Linker url={`/blog/${post.slug}`}>
              <figure className='bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative'>
              </figure>
            </Linker>
            <div className="absolute px-2 py-1 text-sm text-white bg-gray-900 rounded shadow top-4 right-4">
              <time dateTime={post.publishedAt.utc}>{format(parseISO(post.publishedAt.utc), 'MMM do, yyyy')}</time>
            </div>
          </div>
        }

        <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
          <div className="flex-1">

          {post?.category?.slug &&
            <p className="text-sm font-medium tracking-widest text-gray-500 uppercase title-font">
              <Linker url={`/blog/category/${post.category.slug}`} classes="hover:underline">
                {post.category.title}
              </Linker>
            </p>}

            <h3 className="mt-2 mb-1 text-xl font-semibold text-gray-900">
              <a href={`/blog/${post.slug}`} className="hover:underline">  {post.title}
              </a>
            </h3>

            {post.summary && <p className="text-base text-gray-500">{post.summary}</p>}
          </div>

        </div>
      </article>
    )}
  </Grid>
  </div>
  )
}
