import PropTypes from 'prop-types'
// import Link from 'next/link';
// import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
// import { Linker } from '@/components/content/linker'
import { Card } from '@/components/content/card'
import { Grid } from "@/components/content/grid";

import imageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import { config } from '@/lib/sanity.config'
const builder = imageUrlBuilder(config)

CardWrapper.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cards: PropTypes.array,
}

export function CardWrapper(props: { layout: string; tagline: string; title: string; text: string; cards: any; }) {
  const imageScaled = (val: SanityImageSource) => {
    return val ? `${builder.image(val).auto('format').width(402).url()}` : null
  }
  const imageBlur = (val: SanityImageSource) => {
    return val ? `${builder.image(val).auto('format').width(10).url()}` : null
  }

  const { layout, title, tagline, text, cards } = props;

  if (layout === 'centered') {
    return (
      <section className="mb-8 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}
          </div>

          {/* Cards */}
          {cards &&
            <div className="mt-12">
              <Grid gridSize={cards?.length}>

                {cards.map((card) => <Card title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}

              </Grid>
            </div>
          }

        </div>
      </section>
    )
  } else if (layout === 'side-by-side') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        {/* Tagline */}
        {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

        <div className="relative bg-white md:grid md:grid-cols-12 md:gap-8">

          <div className="max-w-2xl md:col-span-4 md:text-left">

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}
          </div>

          {/* Cards */}
          {cards &&
            <div className="relative mt-12 sm:overflow-hidden sm:mx-auto md:mt-0 md:mx-0 md:col-span-8">
              <Grid gridSize={cards?.length}>
                {cards.map(card => <Card title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
              </Grid>
            </div>
          }
        </div>
      </section>
    )
  } else { // Stacked (default) layout
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}

          </div>

          {/* Cards */}
          {cards &&
            <div className="mt-12">
              <Grid gridSize={cards?.length}>
                {cards.map(card => <Card title={card?.title} tagline={card?.tagline} text={card?.text} url={card?.url} image={imageScaled(card.cardImage?.image?.asset)} imageBlur={imageBlur(card?.cardImage?.image?.asset)} imageAltText={card?.cardImage?.alt} key={card._key} callToAction={card?.callToAction} />)}
              </Grid>
            </div>
          }
        </div>
      </section>
    )
  }
}
