import PropTypes from 'prop-types'
// import Link from 'next/link';
// import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
// import { Linker } from '@/components/content/linker'
import { Featurette } from '@/components/content/feature/featurette'
import { Grid } from "@/components/content/grid";
import React from 'react';

Feature.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  featuretteStyle: PropTypes.string,
  featurettes: PropTypes.array,
}

// const TitleComponent = ({ linkUrl, title }) => {
//  if(linkUrl){
//   return <Linker url={linkUrl}><a><h2 className="inline-block mb-2 text-lg font-medium text-gray-900 title-font hover:underline">{title}</h2></a></Linker>
//  } else {
//   return <h2 className="inline-block mb-2 text-lg font-medium text-gray-900 title-font hover:underline">{title}</h2>
//  }
// }

export function Feature(props: { layout: string; tagline: string; title: string; text: string; featuretteStyle: string; featurettes: any; }) {

  const { layout, title, tagline, text, featuretteStyle, featurettes } = props

  if (layout === 'centered') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}
          </div>

          {/* Featurettes */}
          {featurettes &&
            <div className="mt-12">
              <Grid gridSize={featurettes?.length}>

                {featurettes?.map((feature: { _key: string; featuretteStyle: string; title: string; text: string; url: string; icon: any; }) => (
                  <Featurette key={feature._key} featuretteStyle={featuretteStyle} title={feature.title} text={feature.text} url={feature.url} icon={feature.icon} />
                ))}

              </Grid>
            </div>
          }


        </div>
      </section>
    )
  } else if(layout === 'side-by-side') { // Side-by-side
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
         {/* Tagline */}
         {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

        <div className="relative bg-white md:grid md:grid-cols-12 md:gap-8">

          <div className="max-w-2xl md:col-span-4 md:text-left">

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}

          </div>

          {/* Featurettes */}
          {featurettes &&
            <div className="relative mt-12 sm:overflow-hidden sm:mx-auto md:mt-0 md:mx-0 md:col-span-8">
              <Grid gridSize={2}>

                {featurettes?.map((feature: { _key: string; title: string; text: string; url: string; icon: any; }) => (
                  <Featurette key={feature._key} title={feature.title} text={feature.text} url={feature.url} icon={feature.icon} featuretteStyle={featuretteStyle} />
                ))}

              </Grid>
            </div>
          }
        </div>
      </section>
    )
  } else { // Stacked (default) layout
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text &&
              <p className="mt-5 text-xl text-gray-500 max-w-prose">
                {text}
              </p>}

          </div>

          {/* Featurettes */}
          {featurettes &&
            <div className="mt-12">
              <Grid gridSize={featurettes?.length}>

                {featurettes?.map((feature: { _key: string; title: string; text: string; url: string; icon: any; }) => (
                  <Featurette key={feature._key} title={feature.title} text={feature.text} url={feature.url} icon={feature.icon} featuretteStyle={featuretteStyle} />
                ))}

              </Grid>
            </div>
          }
        </div>
      </section>
    )
  }
}


